.tableContainer {
  overflow-x: auto;
}
.tableContainer table {
  padding: 18px;
  border: 1px solid #ccc;
  width: 100%;
}
.table thead {
  background-color: #faa619;
}
.table thead th {
  min-width: 100px;
}
.thead tr th {
  padding: 10px;
  border-width: 1px;
  text-align: center;
}
.tbody tr td {
  padding: 10px;
  border-width: 1px;
  text-align: center;
}
.titleContainer {
  margin-bottom: 8px;
}
.tbody button {
  width: 100%;
  border: none;
  background: none;
}
.tbody button:hover {
  border: none;
  background: #ffe066;
}
.totalcolumnheader {
  border-right: 10px solid black; /* Adjust the border properties as needed */
}

.totalcolumn {
  border-right: 10px solid black; /* Adjust the border properties as needed */
}
.floatContainer{
  max-width: 50px;
  border: 2px solid #faa619;
  position: fixed;
  top:30%;
  left:2%
}
.floatFlex {
display: flex;
justify-content: start;
align-items: center;
}
.floatBox{
  min-height: 20px;
  background-color: #fff9db;
}
.flotBoxActive{
  background-color: #ffe066;
  transform: rotate(90deg);
  transition: all 1s ease-out;}