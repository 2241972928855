.loginform_container {
  margin-top: 100px;
  padding: 40px;

  background: #f9f9f9;
  border: 1px solid transparent;
}
.login_wrapeer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.visitor_row_button {
  background: none;
  border: none;
}

.visitors_modal_textarea {
  margin-bottom: 10px;
  width: 100%;
  border-radius: 3px;
  border: 3px solid #adb5bd;
  /* //new */
  border-color: #d6dae3;
  border: 1px solid #ebedf2;
  font-size: 0.8125rem;
  color: #495057;
  background-color: #ffffff;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.visitors_modal_textarea:focus {
  border-color: #adb5bd;
  /* //new */
  color: #495057;
  background-color: #fff !important;
  border-color: #80bdff !important;
}

.inviteGuestModal .modal-dialog {
  max-width: 1000px;
}
.inviteGuestForm .visitors_modal_input {
  padding: 10px;
}

.checkInForm,
.inviteGuestForm {
  padding: 0 1rem;
}

.inviteGuestForm input[type="radio"] {
  /* hide original inputs */
  visibility: hidden;
  position: absolute;
}
.inviteGuestForm input[type="radio"] + label {
  cursor: pointer;
}
.inviteGuestForm input[type="radio"] + label:before {
  margin-right: 10px;
  content: " ";
  display: inline-block;
  vertical-align: middle;
  transition: 0.3s;
  border: 1px solid rgb(250, 165, 27);
  border-radius: 10px;
  transition: 0.3s;
}
/* DEFAULT */
.inviteGuestForm input[type="radio"] + label:before {
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
/* CHECKED */
.inviteGuestForm input[type="radio"]:checked + label:before {
  box-shadow: inset 0px 0px 0px 2px #fff, inset 0px 0px 0px 8px orange;
}

.inviteGuestForm option {
  font-size: 16px;
}
.visitors_modal_input {
  margin-bottom: 10px;
  display: block;
  width: 100%;
  border-radius: 3px;
  border: 3px solid #adb5bd;
  padding: 10px;
  /* //new */
  border-color: #d6dae3;
  border: 1px solid #ebedf2;
  font-size: 0.8125rem;
  color: #495057;
  background-color: #ffffff;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.visitors_modal_input:focus,
.visitors_modal_input:active {
  border-color: #adb5bd;
  /* //new */
  color: #495057;
  background-color: #fff;
  border-color: #80bdff !important;
}
.updateGuestForm .visitors_modal_input {
  padding: 10px;
}
.inviteGuestForm .visitors_modal_input {
  padding: 10px;
}
.checkInForm .visitors_modal_input {
  margin-bottom: 5px;
}
.visitor_select_minute {
  position: absolute;
  top: 10px;
  right: 30px;
  /* // font-weight: 600; */
  text-transform: capitalize;
}
.visitors_modal_textarea {
  margin-bottom: 10px;
  width: 100%;
  border-radius: 3px;
  border: 3px solid #adb5bd;
  /* //new */
  border-color: #d6dae3;
  border: 1px solid #ebedf2;
  font-size: 0.8125rem;
  color: #495057;
  background-color: #ffffff;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.visitors_modal_textarea:focus {
  border-color: #adb5bd;
  /* //new */
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
}

.inputError {
  color: red;
  /* // font-weight: 600; */
  margin-bottom: 5px;
}
.date_error input:out-of-range {
  background-color: #ffe3e3;
}
.date_error input:out-of-range + label::after {
  content: " Enter Valid Date";
}
.time_error input:out-of-range {
  background-color: #ffe3e3;
}
.time_error input:out-of-range + label::after {
  content: " Enter Valid Time";
}
.visitor_icon {
  font-size: 20px;
  margin-right: 0.1rem;
}
.visitorpage button {
  color: #fff;
  background-color: #fc9200;
  border-color: #fc9200;
}
.visitorpage button:hover {
  color: #fc9200 !important;
  background-color: #fff !important;
  border-color: #fc9200 !important;
}
.reactBigCalender .rbc-btn-group button:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  color: rgb(49, 48, 48);
  background-color: #e6e6e6;
  transform: scale(1.05);
}
.reactBigCalender .rbc-btn-group button:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  transform: scale(0.95);
  background-color: #e6e6e6;
  color: black;
}

.reactBigCalender .rbc-btn-group button:focus {
  outline: none;
}

.reactBigCalender .rbc-toolbar {
  margin-bottom: 25px;
}
.booked-slot {
  background-color: lightgray; /* Example style to show booked */
  opacity: 0.5; /* Fades out to indicate unavailability */
  pointer-events: none; /* Disables pointer events */
}
.rbc-label.rbc-time-header-gutter {
  position: relative;
}

.rbc-label.rbc-time-header-gutter::after {
  content: "Time";
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s;
}

.rbc-label.rbc-time-header-gutter:hover::after {
  opacity: 1;
}
.rbc-row-content .rbc-row-segment .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #ff9800 !important;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-row-content .rbc-row .rbc-row-segment .rbc-button-link .rbc-show-more {
  color: #ff9800;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #ff9800 !important;
  background: #ff9800 !important;
}

.rbc-day-slot .rbc-event {
  border: 1px solid #ff9800 !important;
  background: #ff9800 !important;
}

.rbc-day-slot
  .rbc-time-column
  .rbc-now
  .rbc-today
  .rbc-events-container.rbc-event {
  border: 1px solid #ff9800;
  background: #ff9800;
}

/* CONFIRM MODAL */
.visitor_button {
  color: #fff;
  background-color: #fc9200;
  border-color: #fc9200;
}
.visitor_button:hover {
  color: dodgerblue !important;
  background-color: #fff !important;
  border-color: dodgerblue !important;
}

.visitors_modal_input_flex {
  display: flex;
  column-gap: 10px;
}
.visitor_select_hour {
  position: absolute;
  top: 10px;
  right: 30px;
  /* // font-weight: 600; */
  text-transform: capitalize;
}
.visitor_select_minute {
  position: absolute;
  top: 10px;
  right: 30px;
  /* // font-weight: 600; */
  text-transform: capitalize;
}
.selectParent {
  position: relative;
  width: 100%;
}
.visitors_modal_lable,
.visitors_modal_lable_radio {
  text-transform: capitalize !important;
  font-family: "Ubuntu", sans-serif;
  font-size: 13px;
  padding-bottom: 5px;
  color: #343a40 !important;
  font-weight: 550;
}
.w-100 {
  width: 100%;
}
.visitors_modal_input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
}
.visitors_modal_textarea:focus,
.visitors_modal_textarea:active {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (max-width: 991px) {
  .mobile_screen {
    display: none;
  }
  .system_screen {
    display: block;
  }
}

@media (min-width: 991px) {
  .mobile_screen {
    display: block;
  }
  .system_screen {
    display: none;
  }
}

.datefilter {
  width: 300px;
  max-width: 100%;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;

  [class*="fa-"] {
    font-size: 22px;
    margin-left: 10px;
    cursor: pointer;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
  }
}

.datefilter {
  margin-left: inherit !important;
}

.datefilter {
  width: 100%;
  max-width: 100%;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
}

.reactBigCalender .rbc-btn-group {
  display: inline-flex;
  white-space: nowrap;
  background-color: #ff9800 !important;
  border: none;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 300ms ease-in-out;
  padding: 5px;
}
.reactBigCalender .rbc-btn-group button {
  border: none;
  background: transparent;
  font-size: 16px;
  padding-left: 5px;
  margin-right: 1mm;
  transition: box-shadow 300ms ease-in-out, transform 300ms ease-in-out,
    color 300ms ease-in-out;
}
.rbc-toolbar-label,
.rbc-label {
  color: #343a40;
}
.reactBigCalender .rbc-btn-group button:last-child {
  margin-right: 0;
}
.rbc-label .rbc-time-header-gutter {
  content: Slot;
}
.rbc-toolbar .rbc-btn-group .custom-today-button {
  margin-right: 10px; /* Apply desired spacing */
}
.reactBigCalender .rbc-btn-group button:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  color: rgb(49, 48, 48);
  background-color: #e6e6e6;
  transform: scale(1.05);
}

.reactBigCalender .rbc-btn-group button:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  transform: scale(0.95);
  background-color: #e6e6e6;
  color: black;
}

.reactBigCalender .rbc-btn-group button:focus {
  outline: none;
}

.reactBigCalender .rbc-toolbar {
  margin-bottom: 25px;
}
.booked-slot {
  background-color: lightgray; /* Example style to show booked */
  opacity: 0.5; /* Fades out to indicate unavailability */
  pointer-events: none; /* Disables pointer events */
}
.rbc-label.rbc-time-header-gutter {
  position: relative;
}

.rbc-label.rbc-time-header-gutter::after {
  content: "Time";
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s;
}

.rbc-label.rbc-time-header-gutter:hover::after {
  opacity: 1;
}
.rbc-row-content .rbc-row-segment .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #ff9800 !important;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-row-content .rbc-row .rbc-row-segment .rbc-button-link .rbc-show-more {
  color: #ff9800;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #ff9800 !important;
  background: #ff9800 !important;
}

.rbc-day-slot .rbc-event {
  border: 1px solid #ff9800 !important;
  background: #ff9800 !important;
}

.rbc-day-slot
  .rbc-time-column
  .rbc-now
  .rbc-today
  .rbc-events-container.rbc-event {
  border: 1px solid #ff9800;
  background: #ff9800;
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.confirm_model_cancel > .modal {
  background: orange;
}
.SvgjsPath1057 {
  stroke-width: 22;
}
#apexcharts-radialbarTrack-0 , .apexcharts-radial-series path{
  stroke-width: 10px;
}

.text-mg_clr {
  color: #ff9800 !important;
}


.property-input-wrapper {
  position: relative;
}

.property-list {
  list-style: none;
  position: absolute;
  /* bottom: 100%; Position the list above the input */
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: rgb(158, 149, 149);
  border: 1px solid #ccc;
  z-index: 10;
  padding-left: 0 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
}

.property-item {
  padding: 8px;
  cursor: pointer;
}

.property-item:hover {
  background-color: #f0f0f0;
}

.error-message {
  color: red;
  font-size: 12px;
}
