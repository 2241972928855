.reactBigCalender .rbc-btn-group {
  display: inline-flex;
  white-space: nowrap;
  background-color: #ff9800 !important;
  border: none;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 300ms ease-in-out;
  padding: 5px;
}
.reactBigCalender .rbc-btn-group button {
  border: none;
  background: transparent;
  font-size: 16px;
  padding-left: 5px;
  margin-right: 1mm;
  transition: box-shadow 300ms ease-in-out, transform 300ms ease-in-out,
    color 300ms ease-in-out;
}
.rbc-toolbar-label,
.rbc-label {
  color: #343a40;
}
.reactBigCalender .rbc-btn-group button:last-child {
  margin-right: 0;
}
.rbc-label .rbc-time-header-gutter {
  content: Slot;
}
.rbc-toolbar .rbc-btn-group .custom-today-button {
  margin-right: 10px;
}
.reactBigCalender .rbc-btn-group button:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  color: rgb(49, 48, 48);
  background-color: #e6e6e6;
  transform: scale(1.05);
}
.reactBigCalender .rbc-btn-group button:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  transform: scale(0.95);
  background-color: #e6e6e6;
  color: black;
}
.reactBigCalender .rbc-btn-group button:focus {
  outline: none;
}
.reactBigCalender .rbc-toolbar {
  margin-bottom: 25px;
}
.booked-slot {
  background-color: lightgray; /* Example style to show booked */
  opacity: 0.5; /* Fades out to indicate unavailability */
  pointer-events: none; /* Disables pointer events */
}
.rbc-label.rbc-time-header-gutter {
  position: relative;
}
.rbc-label.rbc-time-header-gutter::after {
  content: "Time";
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s;
}
.rbc-label.rbc-time-header-gutter:hover::after {
  opacity: 1;
}
.rbc-row-content .rbc-row-segment .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #ff9800 !important;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-row-content .rbc-row .rbc-row-segment .rbc-button-link .rbc-show-more {
  color: #ff9800;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #ff9800 !important;
  background: #ff9800 !important;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #ff9800 !important;
  background: #ff9800 !important;
}
.rbc-day-slot
  .rbc-time-column
  .rbc-now
  .rbc-today
  .rbc-events-container.rbc-event {
  border: 1px solid #ff9800;
  background: #ff9800;
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-primary {
  color: #fc9200 !important;
}
.gradient-primary {
  background: linear-gradient(90deg, #fdd195, #fc9200);
  border: none;
  color: white;
}
.form-group {
  margin-bottom: 15px; /* Add space below each form group */
}

.form-content .row {
  margin-left: -15px;
  margin-right: -15px;
}

.form-content .col-md-3,
.form-content .col-md-6 {
  padding-left: 15px;
  padding-right: 15px;
}

.label-black {
  color: #000; /* Ensure all labels are black */
}

.form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: "#fc9200";
  color: #fff;
  font-size: 1em;
}
