.Reopen-error-text{
    text-indent: 2px;
    color: orange;
    font-style: italic;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    padding-top: 4px;
}
.form-error{
    color: red;
    font-size: 18px;
    font-weight: 600;
}