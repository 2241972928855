.chart {
  border: 1px solid #fff2f2;
  border-radius: 10px;
  margin: 3px;
  padding: 3px;
}
.chart:hover {
  transform: scale(1.0105);
  transition-delay: 100ms;
  transition-duration: 160ms;
}
.cct_tab .active {
  border-left: 8px solid #aa4a1a;
}
.date_events {
  text-decoration: none;
    // background: rgb(219 219 219);
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
    color: #fff !important;
    padding: 6px;
    margin-top: 4px;
    border-radius: 7px;
    cursor: pointer;
}
.date_events span {
  text-decoration: none !important;
  color: #637381;
}
.view_more_btn {
  color: rgb(250, 166, 25) !important;
  font-weight: 500;
}