.file-input {
  background: #fff;
  padding: 15px;
  border: 1px solid #d6dae3;
}
.file-input .img {
  height: 80px;
  width: 80px;
  object-fit: contain;
  background: #f2edf3;
}
.file-input .img-group {
  position: relative;
}
.file-input .cancel {
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fe7c96;
  background-color: #fff;
  font-size: 20px;
  padding: 0;
  line-height: 1;
  border: 1px solid;
}
