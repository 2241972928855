.create-ticket-form {
  display: grid;
  gap: 16px;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

.create-ticket-form label {
  font-weight: bold;
}

.create-ticket-form input,
.create-ticket-form textarea,
.create-ticket-form select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
}

.create-ticket-form input:focus,
.create-ticket-form textarea:focus,
.create-ticket-form select:focus {
  outline: none;
  border-color: #0077ff;
}

.submit-btn {
  background-color: #faa619;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.submit-btn:hover {
  background-color: #faa619;
}

@media screen and (min-width: 768px) {
  .create-ticket-form {
    max-width: 100%;
    margin: 0 auto;
  }
}
.card-img-top {
  max-width: 100px;
  max-height: 150px;
}

.card-top {
  max-width: 100px;
  max-height: 150px;
}
