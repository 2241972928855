*,
:after,
:before {
  box-sizing: border-box;
}
.switch {
  --switch-unit: 24px;
  display: inline-block;
  position: relative;
}
.switch input {
  opacity: 0;
  height: 24px;
  width: 48px;
  position: absolute;
  z-index: -1;
}
.switch label {
  display: block;
  width: calc(var(--switch-unit) * 2);
  background: #c6cdd3;
  cursor: pointer;
  border-radius: var(--switch-unit);
  transition: 0.3s;
}
.switch label:after {
  content: '';
  display: block;
  height: var(--switch-unit);
  width: var(--switch-unit);
  border-radius: 50%;
  background: #fff;
  border: 3px solid #c6cdd3;
  transition: 0.3s;
}
.switch input:checked + label {
  background: #b66dff;
}
.switch input:checked + label:after {
  transform: translate(100%, 0);
  border-color: #b66dff;
}
