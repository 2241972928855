.boxContainer {
  background-color: #fff9db;
  padding: 10px;
  border: 1px solid #fff;
}
.counterBoxButton {
  width: 100%;
  /* padding:8px 0px  ; */
  /* padding: 8px 16px; */
  padding: 0px;
  padding-left: 16px;
  border-radius: 8px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.5px;
  /* border-right: 60px solid red; */
  /* position: relative; */
  background-color: #ffd43b;
  border: 1px solid #ffd43b;
}
.counterBoxActive {
  border-left: 8px solid #e67700;
}
.flexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.counter {
  /* display:inline-block;
padding-right: 16px; */
  /* position: absolute;
    top: 9px;
    right: -40px;
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 4px 8px;
    background-color: #fff; */
  width: 50px;
  background-color: #fff;
  padding: 8px 0;
  height: 100%;
  border-radius: 0px 8px 8px 0px;
  color: #fff;
}
@media only screen and (max-width: 425px) {
  .counterBoxButton {
    width: 90vw;
  }
}
